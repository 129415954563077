import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;
    if (token) {
      request = request.clone({
        headers: request.headers.append('X-Auth-Token', token),
      });
    }

    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401 && e.url.slice(-5) !== 'login') {
          this.authService.storeRoute();
          this.authService.logout();
        }
        throw e;
      }),
    );
  }
}
