import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { toggleAnimation, toggleAnimationParametric } from 'shared/animations/toggle.animation';

/*tslint:disable:directive-selector*/
@Directive({ selector: 'ng-template[expandableCardHeader]' })
export class ExpandableCardHeader {
  constructor(public template: TemplateRef<void>) {
  }
}
@Directive({ selector: 'ng-template[expandableCardBody]' })
export class ExpandableCardBody {
  constructor(public template: TemplateRef<void>) {
  }
}
// TODO changeDetectorError on isOpened
@Component({
  selector: 'app-expandable-card',
  animations: [toggleAnimationParametric],
  exportAs: 'appExpandableCard',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="card-title {{ isOpened ? 'border-bottom' : 'border-0'}}" (click)="toggable ? toggle(!shouldBeOpened) : null">
      <ng-container *ngTemplateOutlet="header.template"></ng-container>
        <i *ngIf="expansionPanel" class="expandable-icon fa fa-chevron-down"></i>
    </div>
    <div class="card-body p-0" [@open]="{ value: shouldBeOpened ? 'open' : 'close', params: { time: time }  }"
         (@open.start)="isOpened = true"
         (@open.done)="isOpened = shouldBeOpened">
      <div class="p-3">
          <ng-container *ngIf="body">
              <ng-container *ngIf="isOpened">
                  <ng-container *ngTemplateOutlet="body.template"></ng-container>
              </ng-container>
          </ng-container>
          <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ExpandableCardComponent implements OnChanges {
  @ContentChild(ExpandableCardHeader) header: ExpandableCardHeader;
  @ContentChild(ExpandableCardBody) body: ExpandableCardBody;
  private _shouldBeOpened = true;

  @HostBinding('class.opened')
  get shouldBeOpened() {
    return this._shouldBeOpened;
  }

  set shouldBeOpened(val) {
    this._shouldBeOpened = val;
    this.openedChange.emit(val);
  }

  isOpened = true;

  @Input() time = '.3s';

  @Input() opened;
  @Output() openedChange = new EventEmitter<boolean>();

  @HostBinding('class.expansion-panel')
  @Input() expansionPanel = false;

  @Input() toggable = true;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const isFirstChange = changes['opened'] && changes['opened'].isFirstChange();
    if (isFirstChange) {
      this.isOpened = this.opened;
      this.shouldBeOpened = this.opened;
    } else {
      this.toggle(this.opened);
    }
  }

  toggle(shouldBeOpened: boolean) {
    if (this.isOpened === false && shouldBeOpened === true && this.body) {
      this.isOpened = true;
      requestAnimationFrame(() => {
        this.shouldBeOpened = shouldBeOpened;
        this.cdRef.detectChanges();
      });
    } else {
      this.shouldBeOpened = shouldBeOpened;
    }
  }
}
