import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultFetchService } from 'dashboard/chunks/default-crud/default-crud-fetch.service';
import { Client } from 'dashboard/pages/refs/clients/clients.module';

@Injectable({ providedIn: 'root' })
export class ClientsFetchService extends DefaultFetchService<Client> {
  constructor(http: HttpClient) {
    super({ baseUrl: '/api/vimb/client' }, http);
  }
}
