import { Observable } from 'rxjs';

export abstract class CrudFetchService<T extends { id? } = any> {
  constructor() {
  }

  abstract get(payload): Observable<T>;

  abstract update(payload: T): Observable<Object>;

  abstract create(payload: T): Observable<Object>;

  abstract delete(payload: T): Observable<Object>;
}
