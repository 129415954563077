import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import { BrowserOptions } from '@sentry/browser';
import * as Sentry from '@sentry/browser';

export const SENTRY_SETTINGS = new InjectionToken<BrowserOptions>('SENTRY_SETTINGS');
export const RELEASE = new InjectionToken<string>('RELEASE');

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(@Inject(SENTRY_SETTINGS) sentrySettings: BrowserOptions, @Inject(RELEASE) release: string) {
    if (!!sentrySettings && !window['Cypress']) {
      Sentry.init({ release, ...sentrySettings });
    }
  }

  handleError(error) {
    Sentry.captureException(error.error || error.message || error.originalError || error);
    throw error;
  }
}
