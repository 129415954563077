import { Ctor } from 'shared/types';
import { Type } from '@angular/core';

export interface ConfigViewerPayload {
  template?: string;
  component?: Type<any>;
}

export abstract class DataProviderFactory<T, P extends ConfigViewerPayload> {
  abstract ctor: Ctor<T>;

  abstract create(data: P): T;
}
