import { ChangeDetectionStrategy, ChangeDetectorRef, Component, TemplateRef } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';

export type modalSizes = 'sm' | 'lg' | 'xl';

export abstract class FormModalAbstract {
    form: FormGroup | FormArray;
    model: any = {};
    template: TemplateRef<any>;
    customHtml: any;
    fields: any[] = [];
    /**/
    modalRef: BsModalRef;
    submit = new Subject();
    err$: Observable<any>;
    buttonTitle: string;
    title = '';
    formlyClass = '';
    modalSize: modalSizes = 'sm';
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-form-modal',
    templateUrl: './form-modal.component.html',
})
export class FormModalComponent extends FormModalAbstract {
    form: FormGroup = new FormGroup({});
    fields: FormlyFieldConfig[] = [];

    constructor(private ref: ChangeDetectorRef) {
        super();
        ref.detach();
        setInterval(() => {
            this.ref.detectChanges();
        }, 0);
    }
}
