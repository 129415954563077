import { Component, TemplateRef } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormModalAbstract } from '../form-modal/form-modal.component';

export interface WizardSteps {
    label: string;
    form?: FormlyFieldConfig[];
    template?: TemplateRef<any>;
}

@Component({
    selector: 'app-wizard-modal',
    templateUrl: './wizard-modal.component.html',
})
export class WizardModalComponent extends FormModalAbstract {
    form: FormArray;
    /* Formly boilerplate */
    private _fields: WizardSteps[];
    set fields(fields: WizardSteps[]) {
        this._fields = fields;
        this.form = new FormArray(fields.map(() => new FormGroup({})));
    }

    get fields() {
        return this._fields;
    }

    constructor() {
        super();
    }

}
