import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean { return false; }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean { return false; }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null { return null; }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}

@Injectable()
export class VariableRouteReuseStrategy implements RouteReuseStrategy {
  private static _instance: RouteReuseStrategy;

  static setRouteReuseStrategy(rrs: RouteReuseStrategy) {
    VariableRouteReuseStrategy._instance = rrs;
  }

  static setDefaultRouteReuseStrategy() {
    VariableRouteReuseStrategy._instance = new DefaultRouteReuseStrategy();
  }

  get _instance() {
    return VariableRouteReuseStrategy._instance;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this._instance.retrieve(route);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this._instance.shouldAttach(route);
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this._instance.shouldDetach(route);
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return this._instance.shouldReuseRoute(future, curr);
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    this._instance.store(route, handle);
  }
}
