import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { toggleElementInArray } from 'shared/utils/utils';

@Injectable()
export class SelectableService {

    private _selectedRows$ = new BehaviorSubject<any[]>([]);

    constructor() { }

    private get selectedRowsArray(): any[] {
        return this._selectedRows$.getValue();
    }

    toggleRowSelection(row: any) {
        const rows = this.selectedRowsArray;
        this._selectedRows$.next(toggleElementInArray<any>(
            row,
            this.selectedRowsArray,
            (el) => rows.findIndex((item) => JSON.stringify(item) === JSON.stringify(el)),
        ));
    }

    getSelectedRows$(): Observable<any[]> {
        return this._selectedRows$.asObservable();
    }

    reset() {
        this._selectedRows$.next([]);
    }
}
