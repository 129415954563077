import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { serializeGet } from 'shared/utils/utils';

export const portalTableQuery = (http: HttpClient, setCount: (n: number) => void, onHttpError, route: ActivatedRoute) =>
    <T extends [{ url: string, body: any }, any, any]>(source: Observable<T>) =>
        source.pipe(
            switchMap(
                ([{ url, body }, customBody, { offset, limit }]) => http
                    .post(url, { ...body, ...customBody, offset, limit })
                    .pipe(
                        catchError((e) => onHttpError(e)),
                    ),
            ),
            filter(it => !!it),
            tap((res: any) => setCount(res.data.total)),
            map((res: any) => res.data.data),
        );

export const dashboardTableQuery = (http: HttpClient, setCount: (n: number) => void, onHttpError, route: ActivatedRoute) =>
    <T extends [{ url: string, body: any }, any, any]>(source: Observable<T>) =>
        source.pipe(
            switchMap(([{ url, body }, customBody, { offset, limit }]) => {
                const urlDelimiter = url.indexOf('?') === -1 ? '?' : '&';
                return http
                    .get(`${url}${urlDelimiter}${serializeGet({ ...body, ...customBody, offset, limit })}`)
                    .pipe(catchError((e) => onHttpError(e)));
            }),
            filter(it => !!it),
            tap((res: any) => setCount(res.data.total)),
            map((res: any) => res.data.items),
        );

export const oldBookingTableQuery = (http: HttpClient, setCount: (n: number) => void, onHttpError, route: ActivatedRoute) =>
    <T extends [{ url: string, body: any }, any, any]>(source: Observable<T>) =>
        source.pipe(
            switchMap(([{ url, body }, customBody, { offset, limit }]) => {
                const urlDelimiter = url.indexOf('?') === -1 ? '?' : '&';
                return http
                    .get(`${url}${urlDelimiter}${serializeGet({ ...body, ...customBody, offset, limit })}`)
                    .pipe(catchError((e) => onHttpError(e)));
            }),
            filter(it => !!it),
            map((res: any) => res.data),
            tap((res: any) => setCount(res.length)),
            map((res) => {
                return res.filter(item => item.place_type_id === 2);
            }),
        );

export type QueryEncoder = typeof portalTableQuery | typeof dashboardTableQuery | typeof oldBookingTableQuery;

export const PAGINATION_WRAPPER_QUERY_SERIALIZER = new InjectionToken<QueryEncoder>(
    'PAGINATION_WRAPPER_QUERY_SERIALIZER',
    {
        providedIn: 'root',
        factory: () => portalTableQuery,
    });
