import { ChangeDetectionStrategy, ChangeDetectorRef, Component, isDevMode } from '@angular/core';
import { LoadingService } from 'shared/modules/loading';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
      <div class="progress animated fixed-top {{ (isLoading$ | async) ? 'my-show' : 'my-hide' }}"
           style="height: 3px; z-index: 100500">
          <div class="progress-bar progress-bar-animated progress-bar-striped bg-danger" role="progressbar"
               style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  isLoading$: Observable<boolean>;

  constructor(loadingService: LoadingService, private cdRef: ChangeDetectorRef) {
    this.isLoading$ = loadingService.isLoading$;

    if (isDevMode()) {
      this.isLoading$ = this.isLoading$.pipe(tap(() => {
        this.cdRef.detach();
        requestAnimationFrame(() => this.cdRef.reattach());
      }));
    }
  }
}
