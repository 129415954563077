import { NgModule } from '@angular/core';
import { DrawerModule } from 'shared/modules/navigation/drawer/drawer.module';
import { NavbarModule } from 'shared/modules/navigation/navbar/navbar.module';
import { SidebarMenuItemModule } from './sidebar-menu-item/sidebar-menu-item.module';

@NgModule({
  imports: [
    SidebarMenuItemModule,
    NavbarModule.forRoot(),
    DrawerModule,
  ],
  exports: [
    NavbarModule,
    DrawerModule,
  ],
})
export class NavigationModule {
}
